import { hook, Hooks } from 'Components/Hooks';

import {
  Button,
  Dialog,
} from './Components';

export function RecallButton({ ctx }) {
  const s = window.app.localeStrings;
  return (
    <Button
      hooks={ctx.hooks}
      enabledProp="canRecall"
      onclick={() => ctx.ctrl.startRecall()}
    >
      {s.recall_button}
    </Button>
  );
}

export function RecallConfirmModal({ ctx: { hooks, ctrl } }) {
  const s = window.app.localeStrings;

  let el;

  hooks.add('isModalOpen', isModalOpen => {
    if (isModalOpen) {
      window.$(el).dialog('open');
    } else {
      window.$(el).dialog('close');
    }
  });

  const buttons = [
    {
      text: s.recall_button,
      click: () => ctrl.recall(),
    },
    {
      text: s.lblCancel,
      click: () => ctrl.cancelRecall(),
    },
  ];

  return (
    <Dialog buttons={buttons} ref={el}>
      <RecallCallInfo ctrl={ctrl} />
    </Dialog>
  );
}

export function RecallErrorModal({ ctx: { hooks, ctrl } }) {
  const s = window.app.localeStrings;

  let el;

  hooks.add('errorCode', errorCode => {
    if (errorCode) {
      window.$(el).dialog('open');
    } else {
      window.$(el).dialog('close');
    }
  });

  const buttons = [
    {
      text: s.lblOK,
      click: () => ctrl.dismissError(),
    }
  ];

  return (
    <Dialog buttons={buttons} alert ref={el}>
      <span use:hook={hooks.text('errorCode', val => window.app.errors[val])} />
    </Dialog>
  );
}

function RecallCallInfo({ ctrl }) {
  const s = window.app.localeStrings;

  const hooks = new Hooks();
  ctrl.on('update', () => hooks.run(ctrl.callInfo));

  const CallInfoField = ({ name, label}) => (
    <tr>
      <td class="bold">{label}:</td>
      <td use:hook={hooks.text(name)} />
    </tr>
  );

  return (
    <div>
      <table class="recall-call-info-table">
        <tbody>
          <CallInfoField name="name" label={s.lblName} />
          <CallInfoField name="callerID" label={s.lblCallerID} />
          <CallInfoField name="conferenceID" label={s.lblConferenceID} />
          <CallInfoField name="subConferenceID" label={s.lblSubConferenceID} />
        </tbody>
      </table>
    </div>
  );
}
