import Api from 'Api/Api';
import ApiContextAuthToken from 'Api/ApiContextAuthToken';

import { Hooks } from 'Components/Hooks';
import { RecallController } from './Controllers';
import {
  RecallButton,
  RecallConfirmModal,
  RecallErrorModal,
} from './RecallUI';

const ctx = {
  ctrl: new RecallController,
  hooks: new Hooks(),
};
const onUpdate = () => ctx.hooks.run(ctx.ctrl);

let recallButton;

export function init() {
  const config = window.app.config;
  const apiConfig = {
    API_URL: config.API_URL,
    API_TIMEOUT: config.AJAX_TIMEOUT,
  };

  Api.addContext(new ApiContextAuthToken('default', apiConfig));

  recallButton = (
    <RecallButton ctx={ctx} />
  );

  // jquery-ui automatically attaches these modals to DOM
  <RecallConfirmModal ctx={ctx} />;
  <RecallErrorModal ctx={ctx} />;

  ctx.ctrl.on('update', onUpdate);
  onUpdate();
}

export function getRecallButton() {
  return recallButton;
}

export function onQueueStatusResponse(calls) {
  ctx.ctrl.onQueueStatusResponse(calls);
}

export function onCallDispatched(call) {
  ctx.ctrl.onCallDispatched(call);
}

export function onCallDialogUpdate(isSelectAllowed) {
  ctx.ctrl.onCallDialogUpdate(isSelectAllowed);
}
