import ApiContext from './ApiContext';
import { ApiError } from './ApiErrors';
import ClientStorage from 'Browser/ClientStorage';

export default class ApiContextAuthToken extends ApiContext {
  constructor(name, config) {
    super(name, config);

    this._localStorageKey = config.localStorageKey;
    this._authToken = null;
  }

  getAuthParams(requestMethod = 'json') {
    switch (requestMethod) {
    case 'json':
      return {
        authToken: {
          token: this.getAuthToken()
        }
      };

    case 'get':
      return {
        authToken: this.getAuthToken(),
      };
    }
  }

  getAuthToken() {
    return this._authToken;
  }

  setAuthToken(token) {
    this._authToken = token;

    if (this._localStorageKey) {
      ClientStorage.write(this._localStorageKey, token);
    }
  }

  getStoredAuthToken() {
    if (!this._localStorageKey) {
      return null;
    }

    return ClientStorage.read(this._localStorageKey);
  }

  clearAuthToken() {
    this._authToken = null;

    this._setDefaultScope();

    if (this._localStorageKey) {
      ClientStorage.delete(this._localStorageKey);
    }
  }

  checkLoggedOut() {
    if (!this._localStorageKey) {
      return false;
    }

    // explicitly check localStorage, ignoring sessionOnly.
    // a logout from any window should logout all other windows,
    // regadless of sessionOnly
    if (ClientStorage.localStorage.read(this._localStorageKey))
      return false;

    if (this.logoutCallback)
      this.logoutCallback();

    return true;
  }

  onSend() {
    this._throwIfLoggedOut();
  }

  onResponse(response) {
    this._throwIfLoggedOut();

    if (!response.authToken)
      throw new ApiError('API_MISSING_AUTH_TOKEN');

    this.setAuthToken(response.authToken);
  }

  onEnvelopeError(err) {
    if (err.tokenError && this.sessionTimeoutCallback) {
      this.sessionTimeoutCallback();
    }
  }

  _throwIfLoggedOut() {
    if (this.checkLoggedOut()) {
      const err =  new ApiError('API_LOGGED_OUT');
      err.cancelled = true;
      throw err;
    }
  }
}
