import EventEmitter from 'events';

import Api from 'Api/Api';
import ClientStorage from 'Browser/ClientStorage';

const RECALL_CLIENT_STORAGE_KEY = 'lastDispatched';
const RECALL_WAIT_TIMEOUT = 5000;


export class RecallController extends EventEmitter {
  constructor() {
    super();

    this._waitingTcsID = null;
    this._waitingLockID = null;
    this._waitingTimeoutID = null;

    this._callInfo = {
      tcsID: null,
      conferenceID: null,
      subConferenceID: null,

      callerID: null,
      name: null,
      notes: null,
      userID: null,
    };

    this._isModalOpen = false;
    this._isSelectAllowed = true;
    this._errorCode = null;
  }

  startRecall() {
    if (!this.canRecall) {
      return;
    }

    const lastDispatched = this._getLastDispatched();
    if (!lastDispatched) {
      this._displayError('ERR_RECALL_NO_TARGET');
      return;
    }

    this._callInfo = lastDispatched;
    this._isModalOpen = true;
    this.emit('update');
  }

  cancelRecall() {
    this._isModalOpen = false;
    this.emit('update');
  }

  recall() {
    if (!this.canRecall) {
      return;
    }

    this._isModalOpen = false;
    this.emit('update');

    this._clearWaitingState();
    this._clearWaitingTimeout();

    Api.defaultContext.setAuthToken(window.app.authToken);

    const { conferenceID, tcsID } = this._callInfo;

    const params = {
      conferenceID,
      callID: tcsID,
    };

    Api.get('Queue', 'recall', params)
      .then(res => {
        this._startWaiting(tcsID, res.recallResult.lockID);
      })
      .catch(err => {
        this._displayError('ERR_UNKNOWN');
      });
  }

  dismissError() {
    this._errorCode = null;
    this.emit('update');
  }

  onQueueStatusResponse(calls) {
    if (!this._waitingTcsID) {
      return;
    }

    const call = calls.find(call => call.tcsID === this._waitingTcsID);
    if (!call) {
      return;
    }

    const lockID = this._waitingLockID;
    this._clearWaitingState();
    this._clearWaitingTimeout();

    ClientStorage.delete(RECALL_CLIENT_STORAGE_KEY);

    window.app.callDialog.open(call.queueName, call.uid, call.callerID, window.app.queues.preConfQueue, false, lockID, call);
  }

  onCallDispatched(call) {
    ClientStorage.writeJSON(RECALL_CLIENT_STORAGE_KEY, call);
  }

  onCallDialogUpdate(isSelectAllowed) {
    this._isSelectAllowed = isSelectAllowed;
    this.emit('update');
  }

  _getLastDispatched() {
    const stored = ClientStorage.readJSON(RECALL_CLIENT_STORAGE_KEY);
    return stored;
  }

  _displayError(errorCode) {
    this._errorCode = errorCode;
    this.emit('update');
  }

  _startWaiting(tcsID, lockID) {
    this._waitingTcsID = tcsID;
    this._waitingLockID = lockID;

    this._clearWaitingTimeout();
    this._waitingTimeoutID = setTimeout(() => {
      this._clearWaitingState();
      this._displayError('ERR_RECALL_TIMEOUT');
    }, RECALL_WAIT_TIMEOUT);
  }

  _clearWaitingState() {
    this._waitingTcsID = null;
    this._waitingLockID = null;
  }

  _clearWaitingTimeout() {
    if (this._waitingTimeoutID) {
      clearTimeout(this._waitingTimeoutID);
    }
    this._waitingTimeoutID = null;
  }

  get callInfo() {
    return this._callInfo;
  }

  get isModalOpen() {
    return this._isModalOpen;
  }

  get canRecall() {
    return !!(this._isSelectAllowed && this._getLastDispatched() && !this._waitingTcsID);
  }

  get errorCode() {
    return this._errorCode;
  }
}
