import { hook } from 'Components/Hooks';

export function Button({ hooks = null, enabledProp = null, onclick = null, children }) {
  return (
    <button
      type="button"
      class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only btn-new"
      use:hook={enabledProp && hooks.prop(enabledProp, 'disabled', val => !val)}
      onclick={onclick}
    >
      <span class="ui-button-text">{children}</span>
    </button>
  );
}

export function Dialog({ title = '', buttons, alert = false, ref, children }) {
  let dialogClass = 'no-close';
  if (alert) {
    dialogClass += ' ui-dialog-alert';
  }

  const register = el => {
    ref(el);

    window.$(el).dialog({
      autoOpen: false,
      closeOnEscape: false,
      modal: true,
      resizable: false,
      width: 500,
      dialogClass,
      title,
      buttons,
    });
  };

  return (
    <div ref={register}>
      {children}
    </div>
  );
}
